<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <XyzTransition
        appear
        xyz="fade down-3 delay-6"
      >
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                class="me-3 "
              />

              <v-img
                :src="require('@/assets/images/svg/oriens-text.svg')"
                max-width="130px"
                alt="logo"
                contain
                eager
                class="app-logo me-3"
              />
            </router-link>
          </v-card-title>

          <div>
            <v-tabs-items
              v-model="currentTab"
            >
              <v-tab-item>
                <v-card-text class="text-center">
                  <p class="text-subtitle-1 font-weight-semibold text--primary mb-2">
                    Lupa password? 🔒
                  </p>
                  <p class="mb-2">
                    Silahkan input email anda dan akan kami beri instruksi selanjutnya untuk memulihkan akunmu.
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="forgotPasswordForm"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="yanto@oriens.com"
                      hide-details="auto"
                      class="mb-4"
                      :rules="[required, emailValidator]"
                    />

                    <v-btn
                      block
                      color="primary"
                      :loading="loading"
                      @click="sendEmail"
                    >
                      Kirim
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text class="d-flex flex-column align-center">
                  <span class="text-2xl mb-4 font-medium">Berhasil!</span>
                  <span class="text-center text-subtitle-1 mb-2">
                    Kami sudah mengirimkan instruksi untuk memulihkan akunmu, silahkan cek inbox emailmu.
                  </span>
                  <span class="text-center text-caption">
                    Email bisa masuk dalam waktu 1x24 jam, harap cek berkala. Jika belum masuk, harap cek folder spam di emailmu.
                  </span>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
            <v-card-actions class="d-flex justify-center align-center">
              <router-link
                to="/"
                class="d-flex align-center text-sm"
              >
                <v-icon
                  size="24"
                  color="primary"
                >
                  {{ icons.mdiChevronLeft }}
                </v-icon>
                <span>Kembali</span>
              </router-link>
            </v-card-actions>
          </div>
        </v-card>
      </XyzTransition>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <XyzTransition
      appear
      xyz="fade left-3"
    >
      <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
      />
    </XyzTransition>

    <!-- tree  -->
    <XyzTransition
      appear
      xyz="fade right-3 delay-3"
    >
      <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
      />
    </XyzTransition>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { emailValidator, required } from '@core/utils/validation'
import { sendForgotPasswordCode } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'

export default {
  setup() {
    const forgotPasswordForm = ref()
    const email = ref('')
    const loading = ref(false)
    const currentTab = ref(0)
    const sendEmail = () => {
      if (forgotPasswordForm.value.validate()) {
        loading.value = true
        apolloClient.mutate({
          mutation: sendForgotPasswordCode,
          variables: {
            email: email.value,
          },
        }).then(({ data }) => {
          loading.value = false
          if (data.sendForgotPasswordCode.status === 'success') {
            currentTab.value += 1
          } else {
            errorHandling(data.sendForgotPasswordCode.msg)
          }
        }).catch(err => {
          loading.value = false
          errorHandling(err)
        })
      }
    }

    return {
      forgotPasswordForm,
      email,
      loading,
      currentTab,
      sendEmail,
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      emailValidator,
      required,

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
